import './TutorialsPage.scss'
import React from 'react'
import Layout from "../../components/layout";
import SEO from "../../components/seo";

const TutorialsPage = () => (
    <Layout>
        <SEO title="Tutorials"/>
        Tutorials
    </Layout>
);

export default TutorialsPage
